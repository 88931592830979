<template>
  <div class="container add-content" @touchmove="handleMove" @click="handleMove">
    <div class="guide" v-if="!isEdit && !more">
      <div class="step0">
        <SvgIcon name="step_checked" size="20" />
      </div>
      <div class="line"></div>
      <div class="step1">
        <div class="num">2</div>
        <div class="step1-text">添加我的第 1 个链接</div>
      </div>
    </div>

    <div style="height:0.22rem;flex-shrink: 0;"></div>
    <div class="group">
      <div class="row" style="align-items:center;justify-content:flex-start;flex:1;" @click="changeSocial">
        <div class="group-title">已选平台</div>
        <div class="expand"></div>
        <img v-if="social.logo.length == 0"
          style="width:0.24rem;height:0.24rem;margin-right: 0.08rem;border-radius: 0.05rem;"
          src="../assets/images/custom_link.png" alt="">
        <img v-else style="width:0.24rem;height:0.24rem;margin-right: 0.08rem;border-radius: 0.05rem;"
          :src="social.logo" alt="">
        <div style="color: #A5A5AD;font-size: 0.14rem;">{{ social.name }}</div>
        <span class="icon-right" style="font-size:0.2rem;color: #74747A;"></span>
      </div>
    </div>
    <div class="group">
      <div class="row" style="align-items:center;justify-content:flex-start;flex:1;">
        <div class="group-title">链接方式</div>
        <div class="expand"></div>
        <div class="choose">
          <div class="choose-sel">
            <span :class="isLink ? 'icon-link' : 'icon-qrcode'" style="font-size:0.16rem"></span>
            <div>{{ isLink ? '链接' : '二维码' }}</div>
          </div>
          <div class="choose-nor" v-if="social.types.length > 1" @click="changeType">
            <span :class="!isLink ? 'icon-link' : 'icon-qrcode'" style="font-size:0.16rem"></span>
          </div>
        </div>

      </div>
      <div :class="showError ? 'note-bg note-bg-error' : 'note-bg'" v-if="isLink">
        <div class="row" style="align-items:center">
          <div class="circle">1</div>
          <span class="note">去『已选平台-我』找到「<span style="font-weight:bold">分享-复制链接</span>」</span>
        </div>
        <div class="row" style="align-items:center;">
          <div class="circle">2</div>
          <span class="note">回到此处<span v-if="link && link.length > 0" style="font-weight:bold">粘贴链接</span><span v-else
              class="bold-note" @click="copy">粘贴链接</span></span>
        </div>
      </div>

      <div :class="showError ? 'note-bg note-bg-error' : 'note-bg'" v-else>
        <div class="row" style="align-items:center">
          <div class="circle">1</div>
          <span class="note">去『已选平台-我』找到「<span style="font-weight:bold">我的二维码</span>」保存</span>
        </div>
        <div class="row" style="align-items:center">
          <div class="circle">2</div>
          <span class="note">回到此处
            <span style="font-weight:bold" v-if="image">上传二维码
            </span><span class="bold-note" v-else @click="$refs.FileInput.click()">上传二维码</span></span>
        </div>
      </div>
      <div class="link-bg" v-if="isLink">
        <input class="input-link" type="text" placeholder="请输入链接" v-model.trim="link" @click.stop="" @blur="checkFocusStatus">
        <a @click="copy" v-if="link && link.length == 0">
          <SvgIcon name="copy_link" size="24" />
        </a>
      </div>
      <div class="qr-bg" v-else>
        <img :src="imageUrl" alt="" v-if="imageUrl" class="qrcode" @click="tapImage">
        <a @click="$refs.FileInput.click()" v-else>
          <img src="../assets/images/upload.png" alt="" class="upload">
        </a>

        <a v-if="imageUrl" class="retry" @click="$refs.FileInput.click()">重新上传</a>

      </div>

      <input ref="FileInput" accept="image/png,image/jpeg,image/gif,image/jpg" type="file" style="display: none;"
        @change="onFileSelect" />

      <!-- <span class="icon-qrcode" style="color:red;font-size:0.4rem"></span> -->
    </div>
    <div class="group" style="align-items:flex-start" v-show="showDetail">
      <div class="group-title">标题</div>
      <div class="group-note">填写在我该平台上的用户名，不填将展示平台名称，如：</div>
      <input class="input-title" type="text" placeholder="请输入标题" v-model.trim="strTitle" @click.stop="" @blur="checkFocusStatus">
      <div class="group-line"></div>
      <div class="group-title">描述</div>
      <div class="group-note">对以上内容的补充描述，如：</div>
      <textarea id="input-desc" class="input-desc" type="text" placeholder="请输入描述" v-model.trim="strDesc"
        @click.stop="" @blur="checkFocusStatus"></textarea>
    </div>
    <a class="del" v-if="isEdit" @click="del">删除此条链接</a>
    <div style="height:1.8rem;flex-shrink: 0;"></div>

    <div class="footer" :style="{ height: !more && !isEdit ? '1.7rem' : '1.28rem' }">
      <a class="link_btn0 btn" @click="commit">{{ isEdit ? '保存修改' : '完成' }}</a>
      <div style="height:0.32rem;flex-shrink: 0;"></div>
      <a class="later" v-if="!more && !isEdit" @click="later">稍后添加</a>

    </div>

    <div class="crop-bg" v-if="dialog">
      <VueCropper v-show="selectedFile" ref="cropper" :src="selectedFile" alt="Source Image"></VueCropper>
    </div>

    <div :class="qrObj.needScroll ? 'qr-bg1 qr-bg-scroll' : 'qr-bg1 qr-bg-center'" v-if="previewQR"
      @click="previewQR = false">
      <div class="close icon-close" style="color:#fff">

      </div>
      <img class="qrcode1" :src="qrObj.url" alt="" @click.stop=""
        :style="{ width: qrObj.width + 'px', height: qrObj.height + 'px' }">
    </div>
  </div>
</template>

<script>
import SvgIcon from '../components/SvgIcon.vue';
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import axios from "axios";
import { isiOS } from '../config/utils';
import inobounce from 'inobounce';
import MonitorKeyboard from '../config/monitor_keyboard.js'
export default {
  name: "link_add",
  props: {
    msg: String
  },
  data() {
    return {
      selectedFile: '',
      image: null,
      imageUrl: '',
      dialog: false,
      files: '',
      social: JSON.parse(localStorage.getItem('lastSocial')),
      // social: JSON.parse(this.$route.query.social),
      more: this.$route.query.more ? (this.$route.query.more == 'true' || this.$route.query.more == true) : false,
      social_id: this.$route.query.is_edit ? this.$route.query.social_id : '',
      isLink: true,
      link: '',
      showDetail: false,
      strTitle: '',
      strDesc: '',
      isEdit: this.$route.query.is_edit ? (this.$route.query.is_edit == 'true' || this.$route.query.is_edit == true) : false,
      showError: false,
      previewQR: false,
      qrObj: {
        url: '',
        width: 1,
        height: 1,
        needScroll: false
      },
    }
  },
  watch: {
    link(str) {
      if (str == undefined) {
        return
      }
      this.showDetail = (str.length > 0 && this.isLink) || this.imageUrl.length > 0
      this.showError = false
      this.saveCache()
    },
    strTitle(str) {
      this.saveCache()
    },
    strDesc(str) {
      this.saveCache()
    },
    image(obj) {
      if (obj != null) {
        this.showDetail = !this.isLink
      }
      this.showError = false;

    }
  },
  mounted() {
    if (isiOS()) {
      inobounce.enable()
    }
    global.addLink = this
    this.getKeyboardState();
    // localStorage.setItem('social', this.$route.query.social)

    this.isLink = this.social.types[0] == 'LINK';

    if (this.isEdit) {
      var _this = this;
      this.$get(this.$api.userSocials + '/' + this.social_id).then(data => {
        _this.isLink = data.show_type == 'LINK'
        _this.link = data.user_url
        // if (data.type == 'QR') {
        //   _this.imageUrl = data.qr_url
        // }
        _this.imageUrl = data.qr_url && data.qr_url.length > 0 ? data.qr_url : data.user_url
        _this.showDetail = true
        _this.strTitle = data.user_nick
        _this.strDesc = data.description
      })
    }
    this.loadCache()
    if (!this.isEdit && !this.more) {
      this.updateLoginStep(2.1)
    }

    var textarea = document.querySelector('#input-desc');

    textarea.addEventListener('input', (e) => {
      textarea.style.height = 'auto'
      textarea.style.height = e.target.scrollHeight + 'px';
    });
    setTimeout(() => {
      var height = textarea.scrollHeight < 44 ? 44 : textarea.scrollHeight
      textarea.style.height = height + 'px';
    }, 100)



  },



  destroyed() {
  },
  beforeDestroy() {
    inobounce.disable()
    this.monitorKeyboard.onEnd()
  },
  methods: {
    handleMove() {
      $('#input-desc').blur();
      $('.input-title').blur();
      $('.input-link').blur();
      this.timer = setTimeout(() => {
        window.scrollTo(0, 0);
      }, 20);
    },
    checkFocusStatus() {
      if (document.activeElement != $('#input-desc') && document.activeElement != $('.input-title') && document.activeElement != $('.input-link')) {
        $('.footer').show();
        this.timer = setTimeout(() => {
          window.scrollTo(0, 0);
        }, 20);
      }
    },
    tapImage() {
      this.previewQR = true
      this.qrObj.url = this.imageUrl
      this.$toast('长按图片识别或保存')
      let imgObj = new Image()
      imgObj.src = this.imageUrl
      var _this = this;
      imgObj.onload = () => {
        var height = 1 * imgObj.height / imgObj.width
        var scroll = false;
        if (height * 250 > document.body.clientHeight) {
          scroll = true
        }
        _this.qrObj.width = 250
        _this.qrObj.height = height * 250
        _this.qrObj.needScroll = scroll
      }
    },
    getKeyboardState() {
      this.monitorKeyboard = new MonitorKeyboard();
      this.monitorKeyboard.onStart();

      // 监听虚拟键盘弹出事件
      this.monitorKeyboard.onShow(() => {
        $('.footer').hide();
      })

      //监听键盘收起的事件
      this.monitorKeyboard.onHidden(() => {
        $('.footer').show();
      })
    },
    updateLoginStep(step) {
      if (global.index) {
        global.index.refresh()
      }
      this.$post(this.$api.frontData, {
        code: 'profile_completed_step',
        value: step
      }).then(data => {
        if (step == 2.2) {
          global.hasLogin = true
          window.localStorage.setItem('hasLogin', '1')
          this.$router.push('/').catch(err=>{})
        }

      })
    },
    loadCache() {
      if (this.isEdit) {
        return
      }
      var linkStr = window.localStorage.getItem(this.social.id && this.social.id.length > 0 ? this.social.id : 'custom_link')
      if (linkStr && linkStr.length > 0) {
        var link = JSON.parse(linkStr)
        if (link.social.id != this.social.id) {
          return
        }
        this.isLink = link.isLink
        this.isEdit = link.isEdit
        this.link = link.strLink
        this.strTitle = link.strTitle,
          this.strDesc = link.strDesc,
          this.imageUrl = link.imageUrl,
          this.social = link.social,
          this.social_id = link.social_id
      }

      if (this.imageUrl.length > 0 || this.link.length > 0) {
        this.showDetail = true
      }
    },
    saveCache() {
      if (this.isEdit) {
        return
      }
      var link = {
        isLink: this.isLink,
        isEdit: this.isEdit,
        strLink: this.link,
        strTitle: this.strTitle,
        strDesc: this.strDesc,
        imageUrl: this.imageUrl,
        social: this.social,
        social_id: this.social_id
      }
      window.localStorage.setItem(this.social.id && this.social.id.length > 0 ? this.social.id : 'custom_link', JSON.stringify(link));
    },
    clearCache() {
      window.localStorage.removeItem(this.social.id && this.social.id.length > 0 ? this.social.id : 'custom_link');
    },
    commit() {
      var _this = this;
      if (this.isLink) {
        if (this.link.length == 0) {
          this.$toast('请粘贴链接地址后再提交\n或选择“稍后添加”', require('../assets/images/toast_tip.png'))
          this.showError = true
          return
        }
        if (this.social.url_rules && this.social.url_rules.length > 0) {
          var isMatch = true
          this.social.url_rules.map((rule) => {
            if (rule.type == 'REGULAR') {
              var re = new RegExp(rule.rule);
              if (re.test(this.link) == false) {
                isMatch = false
              }
            }
            if (rule.type == 'PREFIX') {
              if (this.link.indexOf(rule.rule) != 0) {
                isMatch = false
              }
            }
            if (rule.type == 'CONTAIN' || rule.type == 'SUFFIX') {
              if (this.link.indexOf(rule.rule) == -1) {
                isMatch = false
              }
            }
            // if (rule.type == 'SUFFIX') {
            //   if (this.link.match(/bbb$/) == null) {
            //     isMatch = false
            //   }
            // }
          })

          if (!isMatch) {
            this.$modal(require('../assets/images/toast_tip.png'), '粘贴的链接地址\n与所选平台不匹配！\n建议返回修改', '返回修改', '忽略并提交', () => {
              global.modal.show = false;
            }, () => {
              global.modal.show = false;
              _this.commitData()
            })
            return
          }
        }
      }
      else {
        if (this.imageUrl.length == 0) {
          this.$toast('请上传二维码后再提交\n或选择“稍后添加”', require('../assets/images/toast_tip.png'))
          this.showError = true
          return
        }

      }
      this.commitData()


      // this.$router.go(-2);
    },
    commitData() {
      if (this.isEdit) {
        this.$put(this.$api.userSocials + `/${this.social_id}`, {
          'user_url': this.isLink ? this.link : '',
          'qr_url': this.imageUrl ? this.imageUrl : '',
          'description': this.strDesc,
          'social_id': this.social.id,
          'social_name': this.social.id.length == 0 ? this.social.name : '',
          'show_type': this.isLink ? 'LINK' : 'QR',
          'user_nick': this.strTitle
        }).then((data) => {
          this.clearCache()
          //this.$router.go(-1)
          if (global.index) {
            global.index.refresh()
          }
          this.$router.push('/').catch(err=>{})


        })
        return;
      }
      this.$post(this.$api.userSocials, {
        'user_url': this.isLink ? this.link : '',
        'qr_url': this.imageUrl ? this.imageUrl : '',
        'description': this.strDesc,
        'social_id': this.social.id,
        'social_name': this.social.id.length == 0 ? this.social.name : '',
        'show_type': this.isLink ? 'LINK' : 'QR',
        'user_nick': this.strTitle
      }).then((data) => {
        this.clearCache()
        this.updateLoginStep(2.2)
        if (global.index) {
          global.index.refresh()
        }
      })
    },
    del() {
      var _this = this;
      this.$modal(require('../assets/images/toast_tip.png'), '删除此链接后，将无法恢复，\n确定要删除吗？', '确定，立即删除', '取消，再想想', () => {
        global.modal.show = false;
        _this.$del(_this.$api.userSocials + `/${_this.social_id}`).then(data => {
          if (global.index) {
            global.index.refresh()
          }
          this.clearCache()
          _this.$router.back()
        })
      }, () => {
        global.modal.show = false;
      })
    },

    later() {
      this.clearCache()
      global.hasLogin = true
      window.localStorage.setItem('hasLogin', '1')
      this.$router.push('/').catch(err=>{})
      if (global.index) {
        global.index.refresh()
      }
    },

    changeType() {
      var _this = this;
      if (this.isLink) {
        if (this.link.length > 0) {
          this.$modal(require('../assets/images/toast_tip.png'), '切换选择「二维码」\n将使现有「链接」不被展示\n确定要切换吗？', '确定', '再想想', () => {
            global.modal.show = false;
            _this.isLink = false
          }, () => {
            global.modal.show = false;
          })
        }
        else {
          this.isLink = false
        }

      }
      else {
        if (this.imageUrl.length > 0) {
          this.$modal(require('../assets/images/toast_tip.png'), '切换选择「链接」\n将使现有「二维码」不被展示\n确定要切换吗？', '确定', '再想想', () => {
            global.modal.show = false;
            _this.isLink = true
          }, () => {
            global.modal.show = false;
          })
        }
        else {
          this.isLink = true
        }

      }
      this.saveCache()
      // this.isLink = !this.isLink;
    },

    changeSocial() {
      if (this.isEdit) {

        this.$toast('已添加的链接不能更改平台\n建议重新添加新链接\n并删除原有错误的链接', require('../assets/images/toast_tip.png'))
        return
      }
      this.$router.push({ path: '/platform/all', query: { update: true } }).catch(err=>{})
    },

    async copy() {
      const text = await navigator.clipboard.readText();
      this.link = text;
    },
    saveImage() {
      const userId = this.$route.params.user_id
      this.cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        // const formData = new FormData()
        // formData.append('profile_photo', blob, 'name.jpeg')
        // axios
        //   .post('/api/user/' + userId + '/profile-photo', formData)
        //   .then((response) => {
        //   })
        //   .catch(function (error) {
        //     console.log(error)
        //   })
      }, this.mime_type)
    },
    onFileSelect(e) {

      let file = e.target.files[0]
      // console.log(file)

      var fr = new FileReader();
      var _this = this;
      fr.onload = function () {
        _this.image = fr.result;
      };
      fr.readAsDataURL(file);

      this.uploadFile(file)

      // this.image = file;
      // const file = e.target.files[0]
      // this.mime_type = file.type
      // console.log(this.mime_type)
      // if (typeof FileReader === 'function') {
      //   this.dialog = true
      //   const reader = new FileReader()
      //   reader.onload = (event) => {
      //     this.selectedFile = event.target.result
      //     this.$refs.cropper.replace(this.selectedFile)
      //   }
      //   reader.readAsDataURL(file)
      // } else {
      //   alert('Sorry, FileReader API not supported')
      // }
    },
    uploadFile(file) {
      var _this = this;
      this.$get(this.$api.ossFormUpload, {
        type: 'QRCODE',
        file_ext: 'png'
      }).then(data => {
        var url = data.upload_url;
        var strUrl = data.view_url;
        // _this.imageUrl = strUrl;
        let params = new FormData()

        params.append('OSSAccessKeyId', data.fields.OSSAccessKeyId)
        params.append('Signature', data.fields.Signature)
        params.append('key', data.fields.key)
        params.append('policy', data.fields.policy)
        params.append('file', file, file.name);
        let config = {
          headers: { 'Content-Type': 'multipart/form-data' }

        }
        axios.post(url, params, config).then(res => {
          _this.imageUrl = strUrl;
          _this.saveCache()
        }).catch(error => {
        })
      })
    }
  },

  components: { SvgIcon, VueCropper }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
div {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.qr-bg1 {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #000000BF;
  z-index: 100;
  overflow: scroll;

  .close {
    position: fixed;
    right: 0.24rem;
    top: 0.24rem;
    font-size: 0.24rem;
  }

  .qrcode1 {
    object-fit: cover;
  }
}

.qr-bg-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.qr-bg-scroll {
  padding: 0.7rem 0;
}

.add-content {
  -webkit-overflow-scrolling: touch;
  align-items: center;
  overflow-y: scroll;
  height: 100%;
}

.guide {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.18rem;


  .step0 {

    width: 0.44rem;
    height: 0.44rem;
    border-radius: 0.22rem;
    border: solid 0.04rem #74747AFF;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .line {
    margin-left: -1px;
    width: 0.6rem;
    height: 0.08rem;
    background: linear-gradient(90deg, #74747A 0%, #FFF700 100%);
  }

  .step1 {
    margin-left: -1px;
    height: 0.44rem;
    background: #000000;
    box-shadow: 0rem 0.14rem 0.2rem 0rem rgba(255, 247, 0, 0.4), 0rem 0.08rem 0rem -0.02rem #FFF700;
    border-radius: 0.22rem;
    border: 0.04rem solid #FFF700;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0.04rem;
    padding-right: 0.16rem;

    .num {
      width: 0.28rem;
      height: 0.28rem;
      background: #FFF700;
      border-radius: 0.14rem;
      color: #131314FF;
      font-size: 0.2rem;
      font-weight: bold;
      line-height: 0.28rem;
      font-family: Link;
    }

    .step1-text {
      color: #FFF700;
      margin-left: 0.06rem;
      font-size: 0.14rem;
      font-weight: bold;
    }
  }
}

.group {

  margin-bottom: 0.16rem;
  padding: 0.2rem;
  background: #2C2C2E;
  border-radius: 0.24rem;
  width: 3.43rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  .group-title {
    color: #FFF700FF;
    font-size: 0.16rem;
    font-weight: bold;
  }

  .group-note {
    margin-top: 0.08rem;
    color: #A5A5ADFF;
    font-size: 0.12rem;
    line-height: 0.18rem;
    text-align: left;
  }

  .group-line {
    height: 0.01rem;
    width: 100%;
    background: #404040;
    margin: 0.18rem 0;
  }

  .input-title {
    background: transparent;
    width: 100%;
    margin-top: 0.16rem;
    font-size: 0.13rem;
    margin-left: 0rem;
    caret-color: #FF9900FF;
  }

  .input-desc {
    background: transparent;
    width: 100%;
    margin-top: 0.16rem;
    font-size: 0.13rem;
    margin-left: 0rem;
    caret-color: #FF9900FF;
  }
}

.choose {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 0.28rem;
  border-radius: 0.14rem;
  background-color: #131314FF;

  .choose-sel {
    height: 0.28rem;
    border-radius: 0.14rem;
    line-height: 0.28rem;
    font-size: 0.12rem;
    font-weight: bold;
    background-color: #FFF700FF;
    padding: 0 0.1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #131314FF;
  }

  .choose-nor {
    padding: 0 0.14rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.circle {
  color: #FFF700FF;
  width: 0.12rem;
  height: 0.12rem;
  border: 0.01rem solid #FFF700;
  font-size: 0.08rem;
  line-height: 0.12rem;
  border-radius: 0.06rem;
  text-align: center;
  font-family: link;
  box-sizing: border-box;
}

.footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.32rem;
  box-sizing: border-box;
  background-image: linear-gradient(180deg, rgba(19, 19, 20, 0.00) 0%, #131314 25%);
}

.later {
  color: #74747AFF;
  font-size: 0.14rem;
  margin-bottom: 0.3rem;
}

.del {
  color: #74747AFF;
  font-size: 0.14rem;
  margin-bottom: 0.3rem;
  padding: 0.16rem;
}

.crop-bg {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.note-bg {
  margin: 0.12rem -0.2rem;
  margin-bottom: 0.28rem;

  padding: 0 0.2rem;
  padding-bottom: 0;
  box-sizing: border-box;

  .note {
    color: #A5A5ADFF;
    font-size: 0.12rem;
    margin-left: 0.06rem;
  }

  .bold-note {
    color: #FF9900FF;
    font-weight: bold;
  }
}

.note-bg-error {
  padding: 0.15rem 0.2rem;
  background-color: #002FFFFF;
}

.qr-bg {
  width: 3.03rem;
  height: 1.44rem;
  position: relative;
  border-radius: 0.16rem;
  border: dashed 0.01rem #444447;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  .upload {
    width: 0.9rem;
    height: 0.9rem;
  }
}

.link-bg {
  display: flex;
  flex-direction: row;
  align-items: center;

  .input-link {
    flex: 1;
    background-color: transparent;
    caret-color: #FF9900FF;
  }
}

.qrcode {
  width: 1.2rem;
  height: 1.2rem;
  object-fit: contain;
}

.retry {
  position: absolute;
  right: 0.12rem;
  top: 0.12rem;
  width: 0.68rem;
  height: 0.28rem;
  border-radius: 0.14rem;
  background-color: #131314BF;
  line-height: 0.28rem;
  text-align: center;
  color: #FF9900FF;
  font-size: 0.12rem;
  font-weight: bold;
}
</style>